export const formatDate = (dateGmt: string) => {
    const date = new Date(dateGmt);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear().toString().slice(-2);
    const formattedDay = day < 10 ? '0' + day : day;
    const formattedMonth = month < 10 ? '0' + month : month;

    return `${formattedDay}.${formattedMonth}.${year}`;
}

export const excludedUri = ["/", "/home", "/home/"];
